<template>
  <div
    class="enext-input enext-input--checkbox"
    :class="[
      rules && rules.length ? 'enext-input--validable' : '',
      errorMessage ? 'invalid' : '',
      $attrs?.class ? $attrs.class : '',
    ]"
  >
    <k-checkbox
      class="checkbox-wrapper"
      v-bind="{
        name: uuid + '_inputtext',
        id: uuid + '_inputtext',
        ...customAttrs,
      }"
      ref="k-check"
    >
      <slot />
      <k-label
        v-if="label"
        class="k-checkbox-label"
        :for="uuid + '_inputtext'"
      >
        <span
          v-html="label"
          :for="uuid + '_inputtext'"
        />
      </k-label>
    </k-checkbox>
    <ErrorMessage
      :error-message="errorMessage"
      class="enext-input__errormessage enext-input--checkbox__errormessage"
    />
  </div>
</template>

<script>
import {Checkbox} from '@progress/kendo-vue-inputs'
import {Label} from '@progress/kendo-vue-labels'
import inputValidationMixin from '~/mixins/input_validation'
import ErrorMessage from '~/components/input-components/ErrorMessage'

export default {
  inheritAttrs: false,
  components: {
    ErrorMessage,
    'k-checkbox': Checkbox,
    'k-label': Label
  },
  mixins: [inputValidationMixin],
  methods: {
    getValue() {
      return this.$refs['k-check'].checked
    }
  },
  props: {
    label: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    customAttrs() {
      let attrs = {...this.$attrs, class: ''}
      return attrs
    }
  }
}
</script>

<style lang="scss">
.enext-input--checkbox {
  display: flex;
  align-items: center;

  .checkbox-wrapper {
    display: flex;
    align-items: center;

    .k-checkbox {
      & + .k-checkbox-label {
        padding-left: $default-margin-padding-width / 2;
        margin-left: 0;
      }
    }
  }
}
</style>
